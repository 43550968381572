import 'prop-types';
import { spawn, select, put } from 'redux-saga/effects';
import { isServerSideRendering } from '@dbh/environment';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { createSelector } from 'reselect';
import { Map, List } from 'immutable';

const REDUX_AND_SAGA_KEY="CancellableReduxSagaSpawn";const CANCELLABLE_SPAWN_CALLED="@dbh/cancellable-redux-saga-spawnCANCELLABLE_SPAWN_CALLED";const CANCELLABLE_SPAWN_TASKS_CANCELLED="@dbh/cancellable-redux-saga-spawnCANCELLABLE_SPAWN_TASKS_CANCELLED";const SAGA_TRIED_TO_SPAWN_CANCELLABLE_TASK_ALREADY_SPAWNED_BY_ANOTHER_SAGA="@dbh/cancellable-redux-saga-spawnSAGA_TRIED_TO_SPAWN_CANCELLABLE_TASK_ALREADY_SPAWNED_BY_ANOTHER_SAGA";

/**
 * @typedef {import('./types').CancellableReduxSagaTask} CancellableReduxSagaTask
 * @typedef {import('./types').DependantSagaName} DependantSagaName
 * @typedef {import('./types').GeneratorName} GeneratorName
 *//**
 * Dispatched when the cancellable spawn effect is called.
 * @param {Object} options .
 * @param {CancellableReduxSagaTask} options.spawnedTask The `redux-saga` `spawn` output.
 * @param {DependantSagaName} options.dependantSagaName This saga is "dependant" on the
 * spawned task, it spawns it (or tries to) with the cancellable spawn effect,
 * and expects it to be running.
 * @param {GeneratorName} options.spawnedGeneratorName .
 * @return {Object} .
 */const cancellableSpawnCalled=withConformsTo("cancellableSpawnCalled",[],a=>{let{spawnedTask:b,dependantSagaName:c,spawnedGeneratorName:d}=a;return {type:CANCELLABLE_SPAWN_CALLED,payload:{spawnedTask:b,dependantSagaName:c,spawnedGeneratorName:d}}});/**
 * Dispatched when the cancellable spawn effect is called with a generator that
 * was already spawned and is now running.
 * @param {Object} options .
 * @param {CancellableReduxSagaTask} options.spawnedTask The `redux-saga` `spawn` output.
 * @param {DependantSagaName} options.dependantSagaName This saga is "dependant" on the
 * spawned task, it spawns it (or tries to) with the cancellable spawn effect,
 * and expects it to be running.
 * @param {GeneratorName} options.spawnedGeneratorName .
 * @return {Object} .
 */const sagaTriedToSpawnCancellableTaskAlreadySpawnedByAnotherSaga=withConformsTo("sagaTriedToSpawnCancellableTaskAlreadySpawnedByAnotherSaga",[],a=>{let{spawnedTask:b,dependantSagaName:c,spawnedGeneratorName:d}=a;return {type:SAGA_TRIED_TO_SPAWN_CANCELLABLE_TASK_ALREADY_SPAWNED_BY_ANOTHER_SAGA,payload:{spawnedTask:b,dependantSagaName:c,spawnedGeneratorName:d}}});/**
 * Dispatched after we cancelled all the cancellable tasks that the given ejected
 * saga spawned.
 * @param {DependantSagaName} ejectedSagaName .
 * @return {Object} .
 */const spawnedTasksCancelled=withConformsTo("spawnedTasksCancelled",[],a=>({type:CANCELLABLE_SPAWN_TASKS_CANCELLED,payload:{ejectedSagaName:a}}));

/**
 * Direct selector to the state domain.
 * @param {Immutable.Map} state .
 * @return {Immutable.Map} .
 */const selectCancellableReduxSagaSpawnState=a=>a.get(REDUX_AND_SAGA_KEY);var selectCancellableReduxSagaSpawnState$1 = selectCancellableReduxSagaSpawnState;

/**
 * @typedef {import('./types').SpawnedGeneratorsDataList} SpawnedGeneratorsDataList
 *//**
 * Returns a selector that returns the spawned generator data for the generator
 * with the given name.
 * @param {string} generatorName .
 * @return {SpawnedGeneratorsDataList} .
 */const makeSelectSpawnedGeneratorDataListByGeneratorName=withConformsTo("makeSelectSpawnedGeneratorDataListByGeneratorName",[],a=>createSelector(selectCancellableReduxSagaSpawnState$1,b=>b.filter(b=>b.get("spawnedGeneratorName")===a)));var makeSelectSpawnedGeneratorDataListByGeneratorName$1 = makeSelectSpawnedGeneratorDataListByGeneratorName;

/**
 * @typedef {import('../types').GeneratorName} GeneratorName
 * @typedef {import('../types').DependantSagaName} DependantSagaName
 *//**
 * Calls `redux-saga` `spawn` and dispatches the cancellable task to the reducer,
 * to be able to cancel it later.
 * @param {Generator} generatorFunction The generator be to spawned.
 * @param {GeneratorName} generatorFunctionName .
 * @param {DependantSagaName} dependantSagaName This saga is "dependant" on the
 * spawned task, it spawns it (or tries to) with the cancellable spawn effect,
 * and expects it to be running.
 * @return {Object} The spawned, cancellable task.
 */const cancellableSpawn=withConformsTo("cancellableSpawn",[],function*(a,b,c){let d;for(var e=arguments.length,f=Array(3<e?e-3:0),g=3;g<e;g++)f[g-3]=arguments[g];if(isServerSideRendering())return d=yield spawn(a,...f),d;const h=yield select(makeSelectSpawnedGeneratorDataListByGeneratorName$1(b));if(!h.isEmpty()){// A cancellable spawned task for the generator with the given name, was
// created by a saga that called `cancellableSpawn`, and is currently
// running. Please note that this function spawns a generator only once;
// if another saga or the same saga calls `cancellableSpawn` with the same
// generator again, this function doesn't spawn it (to avoid duplication),
// but it adds the key of the saga who tried in the reducer, as one of the
// sagas the depend on it. This way we know we can't cancel a spawned
// cancellable task until the sagas that actually spawned it, or other
// sagas that tried to spawn, and want it to be running as long as they
// are running, are stopped and ejected.
const a=h.map(a=>a.get("dependantSagaName")),e=a.includes(c);return (d=h.getIn([0,"spawnedTask"]),e)?d:(yield put(sagaTriedToSpawnCancellableTaskAlreadySpawnedByAnotherSaga({spawnedTask:d,spawnedGeneratorName:b,dependantSagaName:c})),d);// The current generator was already spawned by some other saga (with
// a different `dependantSagaName`); we don't spawn it again, but we need to
// record that another saga is dependant on it.
}return d=yield spawn(a,...f),yield put(cancellableSpawnCalled({spawnedTask:d,spawnedGeneratorName:b,dependantSagaName:c})),d},{allowUnusedParameters:!0});var cancellableSpawn$1 = cancellableSpawn;

const initialState=List();var reducer = (function(a,b){void 0===a&&(a=initialState);const{type:c,payload:d}=b;switch(c){case SAGA_TRIED_TO_SPAWN_CANCELLABLE_TASK_ALREADY_SPAWNED_BY_ANOTHER_SAGA:case CANCELLABLE_SPAWN_CALLED:{const{spawnedTask:b,dependantSagaName:c,spawnedGeneratorName:e}=d;return a.push(Map({spawnedTask:b,dependantSagaName:c,spawnedGeneratorName:e}))}case CANCELLABLE_SPAWN_TASKS_CANCELLED:{const{ejectedSagaName:b}=d;return a.filter(a=>a.get("dependantSagaName")!==b)}default:return a}});

/*
 *
 * Package: `@dbh/cancellable-redux-saga-spawn`.
 *
 */const importCancellableReduxSagaSpawnSaga=import('./index-061926cf.js'/* webpackChunkName: 'dbh.cancellable-redux-saga-spawn.sagas' */);

export { CANCELLABLE_SPAWN_CALLED as C, REDUX_AND_SAGA_KEY as R, SAGA_TRIED_TO_SPAWN_CANCELLABLE_TASK_ALREADY_SPAWNED_BY_ANOTHER_SAGA as S, spawnedTasksCancelled as a, CANCELLABLE_SPAWN_TASKS_CANCELLED as b, cancellableSpawn$1 as c, cancellableSpawnCalled as d, sagaTriedToSpawnCancellableTaskAlreadySpawnedByAnotherSaga as e, importCancellableReduxSagaSpawnSaga as i, reducer as r, selectCancellableReduxSagaSpawnState$1 as s };
