/*
 * Checkbox Theme
 *
 * This contains the 'styled-components' theme for the Checkbox component.
 *
 * Remember to import this object into './app/theme/index.js' and read the comments
 * in that file to activate it.
 */const THEME_KEY_CHECKBOX="checkBox";const modifyCheckboxTheme=a=>{let{colors:{brandThird:b,greyLight:c,greyUltralight:d,white:e,blackLight:f},spacing:{sp5:g,sp6:h}}=a;return {[THEME_KEY_CHECKBOX]:{borderColor:c,activeBorderColor:b,checkColor:b,disabledCheckColor:f,activeBackgroundColor:e,disabledBackgroundColor:d,checkBoxHeight:h,checkBoxWidth:h,/*
     * Since the inline label has 'line-height' greater than '1', it has a small
     * white space at the top. We add a white space at the top of the checkbox too,
     * see `padding-top`.
     */margin:"0 "+g+" 0 0"}}};

export { THEME_KEY_CHECKBOX, modifyCheckboxTheme as default };
