import { createBrowserOnlyLazyComponent } from '@dbh/react-lazy';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import 'prop-types';
import 'react-immutable-proptypes';
import '@dbh/generic-types';
import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { isServerSideRendering } from '@dbh/environment';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import '@dbh/admindev-types';
import { memoizeWithCacheSizeOne } from '@dbh/lodash-extra';
import _toString from 'lodash/toString';
import { makeSelectIsUserSignedIn, makeSelectUserEmail } from '@dbh/user-area-redux';
import _kebabCase from 'lodash/kebabCase';
import { Map } from 'immutable';
import { injectScript } from '@dbh/dom';
import { createSelector } from 'reselect';
import { makeSelectMessages, makeSelectLanguageProviderLocale } from '@dbh/language-provider-redux';
import { colors } from '@dbh/theme-values';
import { makeSelectCustomizedColors } from '@dbh/site-customizations-redux';
import { makeSelectTemplateCode, makeSelectCurrentWebpageRouteReference } from '@dbh/webpages-redux';
import { makeSelectLocationPathname, makeSelectLocale, makeSelectReactRouterPageType, makeSelectCountry } from '@dbh/routing-redux';
import { createSimpleBooleanSelector } from '@dbh/reselect-extra';

const REDUX_AND_SAGA_KEY="chatWidget";const CHAT_WIDGET_SHOWN="@dbh/chat-widget/CHAT_WIDGET_SHOWN";const CHAT_WIDGET_HIDDEN="@dbh/chat-widget/CHAT_WIDGET_HIDDEN";const CHAT_WIDGET_LOAD_SUCCESS="@dbh/chat-widget/CHAT_WIDGET_LOAD_SUCCESS";const CHAT_WIDGET_LOCALE_CHANGED="@dbh/chat-widget/CHAT_WIDGET_CONFIG_CHANGED";const CHAT_WIDGET_LOAD_REQUESTED="@dbh/chat-widget/CHAT_WIDGET_LOAD_REQUESTED";const CONTEXTUAL_TAGS_ADDED_TO_CHAT_WIDGET="@dbh/chat-widget/CONTEXTUAL_TAGS_ADDED_TO_CHAT_WIDGET";const ZENDESK_KEY="5764a697-88fd-44fb-89e2-b6a058c32181";const CHAT_POSITION_BY_TEMPLATE_CODE={default:"80px",[PAGE_TYPES.BookingFormPage]:"36px",[PAGE_TYPES.ViewReservationPage]:"36px",[PAGE_TYPES.HdpPage]:"28px",[PAGE_TYPES.PdpPage]:"136px"};

/**
 * Dispatched when showing the chat widget has been requested.
 * @param {any} payload .
 * @return {Object} .
 */const chatWidgetShown=a=>({type:CHAT_WIDGET_SHOWN,payload:a});const useShowChatWidget=createUseBoundEventCreator(chatWidgetShown);/**
 * Dispatched when hiding the chat widget has been requested.
 * @param {any} payload .
 * @return {Object} .
 */const chatWidgetHidden=a=>({type:CHAT_WIDGET_HIDDEN,payload:a});const useHideChatWidget=createUseBoundEventCreator(chatWidgetHidden);/**
 * Dispatched when loading the chat widget has been requested.
 * @param {Object} payload .
 * @param {number} payload.initialLoadAfterMilliseconds After how many
 * milliseconds does the chat have to be loaded (the first time).
 * @return {Object} .
 */const chatWidgetLoadRequested=withConformsTo("chatWidgetLoadRequested",[],a=>({type:CHAT_WIDGET_LOAD_REQUESTED,payload:a}));const useLoadChatWidget=createUseBoundEventCreator(chatWidgetLoadRequested);/**
 * Dispatched when the chat widget has been loaded correctly.
 * @param {string} timestamp When the chat widget was loaded.
 * @return {Object} .
 */const chatWidgetLoadSuccess=withConformsTo("chatWidgetLoadSuccess",[],a=>({type:CHAT_WIDGET_LOAD_SUCCESS,payload:{timestamp:a}}));/**
 * Dispatched when the locale configuration of the chat widget changes.
 * @param {string} localeIso6391 .
 * @return {Object} .
 */const chatWidgetLocaleChanged=withConformsTo("chatWidgetLocaleChanged",[],a=>({type:CHAT_WIDGET_LOCALE_CHANGED,payload:{localeIso6391:a}}));/**
 * Dispatched when additional contextual tags, associated to the current page,
 * should be added to the chat widget, using its third party JavaScript `API`.
 * They will be removed from the chat widget when the user changes page (they
 * are stored by `pathname` in the reducer).
 * @return {Object} .
 */const contextualTagsAddedToChatWidget=withConformsTo("contextualTagsAddedToChatWidget",[],a=>{let{tags:b,pathname:c}=a;return {type:CONTEXTUAL_TAGS_ADDED_TO_CHAT_WIDGET,payload:{tags:b,pathname:c}}});

/**
 * Converts the tags (to be displayed in the web `UI` of the chat) in the format
 * required by and compatible with the chat widget.
 * @param {Immutable.Map} tagsByName .
 * @return {string[]} .
 */const createZendeskChatWidgetTags=withConformsTo("createZendeskChatWidgetTags",[],a=>{const b=/([,>]|\s)+/g;// @see {@link https://developer.zendesk.com/embeddables/docs/widget/settings#tags}
// Note that tags must be strings and do not accept `,`.
// `ZenDesk` stores the tags in their `DB` in lowercase.
// We remove `,` and white spaces.
return a.reduce((c,d,e)=>{const f=e.replace(b,""),g=_toString(a.get(e)).replace(b,"");// We opted to use `>` as divider because it is hard to use in an email
// address. @see {@link https://stackoverflow.com/a/2049510}
return c.push(f+">"+g),c},[])});var createZendeskChatWidgetTags$1 = createZendeskChatWidgetTags;

/**
 * Adds the provided contextual tags to the chat widget. Does not merge them,
 * so remember to select all the "initial" tags with the selector, plus add the
 * new ones or modify them.
 * @param {Immutable.Map} tags .
 * @see {@link https://developer.zendesk.com/embeddables/docs/widget/core#updatesettings}
 */const addTagsToChatWidget=memoizeWithCacheSizeOne(withConformsTo("addTagsToChatWidget",[],a=>{const b=createZendeskChatWidgetTags$1(a);window.zE&&window.zE("webWidget","updateSettings",{webWidget:{chat:{tags:b}}});}));var addTagsToChatWidget$1 = addTagsToChatWidget;

/**
 * If the user data is provided, returns the values to be prefilled in the
 * offline form of the chat widget.
 * @param {Immutable.Map?} userData .
 * @return {Object?} .
 */const createOfflineFormPrefilledValues=withConformsTo("createOfflineFormPrefilledValues",[],a=>{if(!a)return;const b=a.get("email"),c=[a.get("firstName"),a.get("lastName")].filter(Boolean).join(" ");return {name:{value:c,readOnly:!1},email:{value:b,readOnly:!1}}});var createOfflineFormPrefilledValues$1 = createOfflineFormPrefilledValues;

/**
 * Returns the chat widget tags for the given hotel details.
 * @param {Immutable.Map} hotel .
 * @return {Immutable.Map} .
 */const getChatWidgetTagsFromHotelDetails=withConformsTo("getChatWidgetTagsFromHotel",[],a=>// Try to keep the `tag `length short or it will get ellipsed in the web
// dashboard of the chat widget, providing little value to the customer care.
Map({hotelId:a.get("id"),hotelName:_kebabCase(a.get("name","")),hotelAddress:_kebabCase(a.get("address","")),hotelCity:_kebabCase(a.getIn(["city","name"])),// `country` is already in use.
hotelCountry:a.getIn(["city","country","name"]),hotelPostalCode:a.get("postalCode"),isMysteryHotel:a.get("mystery"),hotelEmail:a.get("email")}));var getChatWidgetTagsFromHotelDetails$1 = getChatWidgetTagsFromHotelDetails;

const fnName="loadChatWidgetApi",loadChatWidgetApi=withConformsTo("loadChatWidgetApi",[],async(a,b)=>{throwInServerSideRendering(fnName),window.zESettings=b;return await injectScript("https://static.zdassets.com/ekr/snippet.js?key="+a,{id:"ze-snippet"}),window.zE("webWidget","hide"),new Date().toISOString()});/**
 * Injects into the `DOM` the script that loads asynchronously the `ZenDesk`
 * chat widget.
 * @return {Promise<string>} Resolves to the timestamp of the load event or
 * rejects if:
 * 1. An error occurs.
 * 2. The `API` takes too much to answer.
 * 3. It is invoked in `SSR` or the types of the parameters are not valid.
 */var loadChatWidgetApi$1 = loadChatWidgetApi;

/**
 * Changes the locale of the chat widget.
 * @param {string} localeIso6391 .
 * @see {@link https://developer.zendesk.com/embeddables/docs/widget/core#setlocale}
 */const changeChatWidgetLocale=withConformsTo("configChatMessages",[],a=>{throwInServerSideRendering("configChatMessages"),window.zE("webWidget","setLocale",a);});/**
 * Returns `true` if the chat widget is visible.
 * @return {boolean} .
 * @see {@link https://developer.zendesk.com/embeddables/docs/widget/core#get-display}
 */const getIsChatWidgetVisible=()=>!isServerSideRendering()&&"function"==typeof window.zE&&"hidden"!==window.zE("webWidget:get","display");/**
 * Hides the badge, for some reason they have not removed the legacy `API` yet.
 * It is not an officially supported `API` and might be removed at any moment.
 */const hideChatBadgeIfPossible=()=>{if("undefined"!=typeof window.$zopim){// We use default values when destructuring to avoid a crash, in case the
// unsupported `API` is removed.
const{livechat:{badge:{hide:a}={}}={}}=window.$zopim;"function"==typeof a&&a();}};

/**
 * Returns a selector that returns the chat widget messages in the current
 * locale, if they exist in the `WebMessages` from the `admindev`.
 * @return {Object?} .
 */const makeSelectChatWidgetMessages=()=>createSelector(makeSelectMessages(),a=>{if(!a)return;const b=a.get("containers.ChatWidget.online_greeting_message"),c=a.get("containers.ChatWidget.offline_greeting_message"),d=a.get("containers.ChatWidget.offline_form"),e=a.get("containers.ChatWidget.window_title"),f=a.get("containers.ChatWidget.concierge_name");return {onlineGreetingMessage:b,offlineGreetingMessage:c,offlineForm:d,windowTitle:e,conciergeTitle:f}});var makeSelectChatWidgetMessages$1 = makeSelectChatWidgetMessages;

/**
 * Returns a selector that returns the current locale in `ISO 6391` format.
 * @return {string?} @example `it` (from `it-IT`) or `en` (from `en-GB`).
 * @see {@link https://en.wikipedia.org/wiki/ISO_639-1}
 */const makeSelectLanguageProviderLocaleIso6391=()=>createSelector(makeSelectLanguageProviderLocale(),a=>a?a.slice(0,a.indexOf("-")):void 0);var makeSelectLanguageProviderLocaleIso6391$1 = makeSelectLanguageProviderLocaleIso6391;

/**
 * Returns a selector that returns the `ZenDesk` chat widget settings, if the
 * widget is enabled in the current site (we don't have support people for all
 * languages, the company can enable or disable the chat widget using geolocation,
 * using its web dashboard). If the `/messages` `API` does not include the chat
 * widget messages for the current locale (@see `makeSelectChatWidgetMessages`
 * for the `IDs`), the `ZenDesk` chat widget uses its default messages for the
 * current locale.
 * @return {Object?} .
 * @see {@link https://developer.zendesk.com/embeddables/docs/widget/chat}
 * @see {@link https://support.zendesk.com/hc/en-us/articles/203761906}
 */const makeSelectChatWidgetSettings=()=>createSelector(makeSelectChatWidgetMessages$1(),makeSelectLanguageProviderLocaleIso6391$1(),makeSelectTemplateCode(),makeSelectCustomizedColors(),(a,b,c,d)=>{if(!a||!b)return;const{onlineGreetingMessage:e,offlineGreetingMessage:f,offlineForm:g,windowTitle:h,conciergeTitle:i}=a,{white:j}=d||colors,k=CHAT_POSITION_BY_TEMPLATE_CODE[c]||CHAT_POSITION_BY_TEMPLATE_CODE.default;return {webWidget:{// @see {@link https://support.zendesk.com/hc/en-us/community/posts/360003215947-New-Feature-Google-Analytics-for-Chat-in-the-Web-Widget}
analytics:!0,color:{launcherText:j,header:j,// `brandThird` darkened by `10%`.
button:"#4c7181"},contactOptions:{enabled:!1},helpCenter:{suppress:!1},answerBot:{suppress:!0},contactForm:{attachments:!0},position:{horizontal:"left",vertical:"bottom",mobile:{horizontal:"left",vertical:"bottom"}},offset:{horizontal:"6px",vertical:k,mobile:{horizontal:"0px",vertical:k}},chat:{concierge:{title:{[b]:i}},offlineForm:{greeting:{[b]:g}},title:{[b]:h}},launcher:{mobile:{labelVisible:!0},chatLabel:{[b]:e},label:{[b]:f}}}}});var makeSelectChatWidgetSettings$1 = makeSelectChatWidgetSettings;

const selectChatWidget=a=>a.get(REDUX_AND_SAGA_KEY);var selectChatWidget$1 = selectChatWidget;

/**
 * Returns a selector that returns the "custom" contextual tags associated to
 * the current page, if any.
 * @return {Immutable.Map?} .
 */const makeSelectCustomTagsForCurrentPage=()=>createSelector(selectChatWidget$1,makeSelectLocationPathname(),(a,b)=>a?a.getIn(["contextualTagsByPage",b]):void 0);var makeSelectCustomTagsForCurrentPage$1 = makeSelectCustomTagsForCurrentPage;

/**
 * Returns a selector that returns the "global" chat widget tags valid for all
 * pages, merged with (if any exist) "custom" tags valid just for the current
 * page.
 * @return {Immutable.Map} Includes generic values like template code, country,
 * locale, user email. If some part of the app has dispatched them, also includes
 * custom tags associated to the current page.
 */const makeSelectContextualTags=()=>createSelector(makeSelectIsUserSignedIn(),makeSelectUserEmail(),makeSelectLocale(),// We use `makeSelectReactRouterPageType` instead of  `makeSelectTemplateCode`
// here, because it's never `undefined` during transitions, while
// `makeSelectTemplateCode` can return `undefined` when navigating to a
// "new" page (while the dynamic import is loading the route).
makeSelectReactRouterPageType(),makeSelectCountry(),makeSelectCustomTagsForCurrentPage$1(),makeSelectCurrentWebpageRouteReference(),(a,b,c,d,e,f,g)=>{// Keep the tag names short or they will be ellipsed in the chat widget's
// web dashboard `UI`.
const h=Map({locale:c,template:d,country:e,userSignedIn:a,...(g?{adminPageRef:g}:{}),...(a&&b?{loggedUserEmail:b}:{})});// We don't allow "custom" tags associated to the current page to overwrite
// the tags valid for all pages.
return f&&!f.isEmpty()?f.merge(h):h});var makeSelectContextualTags$1 = makeSelectContextualTags;

/**
 * Return a selector that returns whether the chat widget is loaded.
 * @return {boolean} `true` if the chat widget is loaded.
 */const makeSelectIsChatWidgetLoaded=()=>createSimpleBooleanSelector(selectChatWidget$1,["info","loadedAt"]);var makeSelectIsChatWidgetLoaded$1 = makeSelectIsChatWidgetLoaded;

var index = createBrowserOnlyLazyComponent(()=>import('./ChatWidget-f2211375.js'/* webpackChunkName: "dbh.chat-widget" */));// Keep the `export *` at the bottom and the other types of exports above.

export { CHAT_POSITION_BY_TEMPLATE_CODE, CHAT_WIDGET_HIDDEN, CHAT_WIDGET_LOAD_REQUESTED, CHAT_WIDGET_LOAD_SUCCESS, CHAT_WIDGET_LOCALE_CHANGED, CHAT_WIDGET_SHOWN, CONTEXTUAL_TAGS_ADDED_TO_CHAT_WIDGET, REDUX_AND_SAGA_KEY, ZENDESK_KEY, addTagsToChatWidget$1 as addTagsToChatWidget, changeChatWidgetLocale, chatWidgetHidden, chatWidgetLoadRequested, chatWidgetLoadSuccess, chatWidgetLocaleChanged, chatWidgetShown, contextualTagsAddedToChatWidget, createOfflineFormPrefilledValues$1 as createOfflineFormPrefilledValues, createZendeskChatWidgetTags$1 as createZendeskChatWidgetTags, index as default, getChatWidgetTagsFromHotelDetails$1 as getChatWidgetTagsFromHotelDetails, getIsChatWidgetVisible, hideChatBadgeIfPossible, loadChatWidgetApi$1 as loadChatWidgetApi, makeSelectChatWidgetMessages$1 as makeSelectChatWidgetMessages, makeSelectChatWidgetSettings$1 as makeSelectChatWidgetSettings, makeSelectContextualTags$1 as makeSelectContextualTags, makeSelectCustomTagsForCurrentPage$1 as makeSelectCustomTagsForCurrentPage, makeSelectIsChatWidgetLoaded$1 as makeSelectIsChatWidgetLoaded, makeSelectLanguageProviderLocaleIso6391$1 as makeSelectLanguageProviderLocaleIso6391, selectChatWidget$1 as selectChatWidget, useHideChatWidget, useLoadChatWidget, useShowChatWidget };
